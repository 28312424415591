import React from 'react';
import './Profile.css';

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="cols-icon">
              <a
                href="https://github.com/chrisbolosan"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-github-square"></i>
              </a>
              <a
                href="https://www.linkedin.com/in/chrisbolosan"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin-square"></i>
              </a>
            </div>
          </div>

          <div className="profile-details-name">
            <span className="primary-text">
              {' '}
              Hi, I'm <span className="highlighted-text">Chris </span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="profile-role-tagline">Software Engineer </span>
          </div>
          <div className="profile-options">
            {/* <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            >
              Hire Me{' '}
            </button> */}
            <a
              className="btn primary-btn"
              href="mailto:cbolosan@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Email Me
            </a>{' '}
            {/* <a
              href="Christopher Bolosan Resume.pdf"
              download="Christopher Bolosan Resume.pdf"
            >
              <button className="btn highlighted-btn">Get Resume</button>
            </a> */}
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
