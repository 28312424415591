import React, { useState, useEffect } from 'react';
import {
  Camera,
  Users,
  Clock,
  Maximize,
  Minimize,
  ChevronRight,
} from 'lucide-react';

const TwitchPreview = () => {
  const [isLive, setIsLive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [viewerCount, setViewerCount] = useState(0);
  const [streamTitle, setStreamTitle] = useState(
    'Stream Offline - Check back soon!'
  );
  const [streamDuration, setStreamDuration] = useState('0:00:00');
  const [streamThumbnail, setStreamThumbnail] = useState('/twitchlogo.jpg');

  useEffect(() => {
    const checkStreamStatus = async () => {
      try {
        const response = await fetch(
          'https://api.twitch.tv/helix/streams?user_login=engineersinthewild',
          {
            headers: {
              'Client-ID': process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID,
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_TWITCH_ACCESS_TOKEN}`,
            },
          }
        );

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        console.log('Twitch API response:', data);

        if (data.data && data.data.length > 0) {
          const stream = data.data[0];
          setIsLive(true);
          setStreamTitle(stream.title);
          setViewerCount(stream.viewer_count);

          const thumbnailUrl = stream.thumbnail_url
            .replace('{width}', '440')
            .replace('{height}', '248');
          setStreamThumbnail(thumbnailUrl);

          const startTime = new Date(stream.started_at);
          const now = new Date();
          const durationInSeconds = Math.floor((now - startTime) / 1000);
          const hours = Math.floor(durationInSeconds / 3600);
          const minutes = Math.floor((durationInSeconds % 3600) / 60);
          const seconds = durationInSeconds % 60;
          setStreamDuration(
            `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
              .toString()
              .padStart(2, '0')}`
          );
        } else {
          setIsLive(false);
          setStreamTitle('Stream Offline - Check back soon!');
          setViewerCount(0);
          setStreamDuration('0:00:00');
          setStreamThumbnail('/twitchlogo.jpg');
        }
      } catch (error) {
        console.error('Error checking stream status:', error);
        setIsLive(false);
      }
    };

    checkStreamStatus();
    const interval = setInterval(checkStreamStatus, 30000);
    return () => clearInterval(interval);
  }, []);

  // Compact view
  if (!isExpanded) {
    return (
      <div className="fixed right-8 bottom-8">
        <div
          className="flex items-center gap-3 px-4 py-3 bg-purple-100 rounded-lg hover:bg-purple-200 transition-all duration-300 cursor-pointer shadow-lg"
          onClick={() => setIsExpanded(true)}
        >
          {/* Live Indicator */}
          {isLive && (
            <div className="relative">
              <div className="absolute inset-0 bg-red-500 rounded-full animate-ping opacity-75"></div>
              <div className="relative w-3 h-3 bg-red-500 rounded-full"></div>
            </div>
          )}

          {/* Icon and Text */}
          <div className="flex items-center gap-2">
            <Camera className="text-purple-500" size={20} />
            <span className="font-medium text-purple-900">
              {isLive ? 'Live on Twitch' : 'Offline'}
            </span>
          </div>

          {/* Viewer Count - Only show when live */}
          {isLive && (
            <div className="flex items-center gap-1 px-2 py-1 bg-purple-200 rounded">
              <div className="w-2 h-2 bg-purple-500 rounded-full"></div>
              <span className="text-sm text-purple-700">{viewerCount}</span>
            </div>
          )}

          <Maximize size={16} className="text-purple-500 ml-2" />
        </div>
      </div>
    );
  }

  // Expanded view
  return (
    <div className="fixed right-8 bottom-8 w-80 bg-white rounded-lg shadow-xl overflow-hidden">
      {/* Header */}
      <div className="bg-purple-900 px-4 py-3 text-white flex items-center justify-between">
        <div className="flex items-center gap-2">
          {isLive && (
            <div className="relative">
              <div className="absolute inset-0 bg-red-500 rounded-full animate-ping opacity-75"></div>
              <div className="relative w-2 h-2 bg-red-500 rounded-full"></div>
            </div>
          )}
          <span className="font-medium">{isLive ? 'LIVE' : 'OFFLINE'}</span>
        </div>
        <button
          onClick={() => setIsExpanded(false)}
          className="hover:text-purple-200 transition-colors"
        >
          <Minimize size={20} />
        </button>
      </div>

      {/* Stream Preview */}
      <div className="relative aspect-video bg-gray-900">
        <img
          src={streamThumbnail}
          alt="Stream preview"
          fill
          className="object-cover"
          priority
        />
        {!isLive && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <span className="text-white font-medium">Currently Offline</span>
          </div>
        )}
      </div>

      {/* Stream Info */}
      <div className="p-4">
        <h3 className="font-medium text-gray-900 mb-3 truncate">
          {isLive ? streamTitle : 'Channel Offline'}
        </h3>

        <div className="space-y-2 mb-4">
          {isLive && (
            <>
              <div className="flex items-center gap-2 text-gray-600">
                <Users size={16} />
                <span className="text-sm">{viewerCount} watching</span>
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <Clock size={16} />
                <span className="text-sm">Live for {streamDuration}</span>
              </div>
            </>
          )}
        </div>

        {/* Action Button */}
        <a
          href="https://www.twitch.tv/engineersinthewild"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center gap-2 w-full bg-purple-600 text-white text-center py-2 rounded-md hover:bg-purple-700 transition-colors duration-300"
        >
          {isLive ? 'Watch Stream' : 'Visit Channel'}
          <ChevronRight size={16} />
        </a>
      </div>
    </div>
  );
};

export default TwitchPreview;
